<template>
    <Teleport to="body">
        <div v-if="tx.data.full_loader" class="flex justify-center items-center h-dvh w-dvw z-150 bg-white relative">
            <div class="border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-blue-600" />
        </div>
    </Teleport>
    <div v-if="!tx.data.full_loader" class="bg-white">
        <header class="absolute inset-x-0 top-0 z-10">
            <nav class="flex items-center justify-between p-6 lg:px-8" >
                <div class="flex lg:flex-1">
                    <a href="#" class="-m-1.5 p-1.5">
                        <img class="h-10 w-auto" src="./assets/logo.svg" alt="" />
                    </a>
                </div>
                <div class="flex lg:hidden">
                    <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = true">
                        <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div class="hidden lg:flex lg:gap-x-12">
                    <span class="text-sm font-semibold text-gray-900 cursor-pointer uppercase">Наши станции</span>
                    <span class="text-sm font-semibold text-gray-900 cursor-pointer uppercase">Информация для водителей</span>
                </div>


                <div v-if="tx.data.user.authorize" class="hidden lg:flex lg:flex-1 lg:justify-end">
                    <div class="ml-4 flex items-center md:ml-6">
                        <!-- Profile dropdown -->
                        <Menu as="div" class="relative ml-3">
                            <div>
                                <MenuButton class="relative flex max-w-xs items-center rounded-full bg-transparent text-sm outline-none">
                                    <div class="flex items-center px-5">
                                        <div class="flex-shrink-0">
                                            <img class="h-10 w-10 rounded-full" src="./assets/no-profile.svg" alt="" />
                                        </div>
                                        <div class="ml-3">
                                            <div class="text-base font-medium leading-none  text-right">{{ tx.data.user.name }}</div>
                                            <div class="text-sm font-medium leading-none text-gray-400">{{ tx.data.user.phone }}</div>
                                        </div>
                                    </div>
                                </MenuButton>
                            </div>
                            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                                        <a @click.prevent="item.event" :href="item.href" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">{{ item.name }}</a>
                                    </MenuItem>
                                </MenuItems>
                            </transition>
                        </Menu>
                    </div>
                </div>
                <template v-else>
                    <div class="hidden lg:flex lg:flex-1 lg:justify-end">
                        <a href="#" @click.prevent="authFormData.open" class="text-sm font-semibold leading-6 text-gray-900 rounded-md ring-2 ring-indigo-600 text-indigo-600 py-1 px-5 uppercase">Вход</a>
                    </div>
                    <div class="hidden lg:flex lg:basis-auto ml-2.5 lg:justify-end">
                        <a href="#" @click.prevent="registerFormData.open" class="text-sm font-semibold leading-6 text-gray-900 rounded-md ring-2 ring-indigo-600 text-indigo-600 py-1 px-5 uppercase">Регистрация</a>
                    </div>
                </template>
            </nav>
            <Dialog class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
                <div class="fixed inset-0 z-50" />
                <DialogPanel class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div class="flex items-center justify-between">
                        <a href="#" class="-m-1.5 p-1.5">
                            <img class="h-8 w-auto" src="./assets/logo.svg" alt="" />
                        </a>
                        <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div class="mt-6 flow-root">
                        <div class="-my-6 divide-y divide-gray-500/10">
                            <div class="space-y-2 py-6">
                                <a href="#" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Наши станции</a>
                                <a href="#" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Информация для водителей</a>
                            </div>

                            <div v-if="tx.data.user.authorize" class="border-t border-gray-700 pb-3 pt-4">
                                <div class="flex items-center px-5">
                                    <div class="flex-shrink-0">
                                        <img class="h-10 w-10 rounded-full" src="./assets/no-profile.svg" alt="" />
                                    </div>
                                    <div class="ml-3">
                                        <div class="text-base font-medium leading-none">{{ tx.data.user.name }}</div>
                                        <div class="text-sm font-medium leading-none text-gray-400">{{ tx.data.user.phone }}</div>
                                    </div>
                                </div>
                                <div class="mt-3 space-y-1 px-2">
                                    <a v-for="item in userNavigation" :key="item.name" :href="item.href" @click.prevent="item.event" class="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">{{ item.name }}</a>
                                </div>
                            </div>


                            <div v-else class="py-6">
                                <a href="#" @click.prevent="authFormData.open" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Вход</a>
                                <a href="#" @click.prevent="registerFormData.open" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Регистрация</a>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>

        <TransitionRoot as="template" :show="registerFormData.visible">
            <Dialog class="relative z-150" @close="registerFormData.close">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div class="fixed inset-0 w-screen overflow-y-auto">
                    <div class="flex min-h-full items-center justify-center p-4 text-center">
                        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all max-md:grow md:basis-3/6 lg:basis-96">
                                <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div class="flex items-start">
                                        <div class="mt-3 sm:ml-4 sm:mt-0 sm:text-left flex-grow">
                                            <div class="flex items-center justify-between">
                                                <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900 uppercase">Регистрация</DialogTitle>
                                                <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="registerFormData.close">
                                                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                            <form>
                                                <div class="border-b border-gray-900/10 pb-12">
                                                    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 grid-cols-6">
                                                        <div class="col-span-full">
                                                            <p v-if="'global' in registerFormData.errors" class="text-red-500 text-xs italic" v-html="registerFormData.errors.global.join('<br />')" />
                                                        </div>
                                                        <div class="col-span-full grow">
                                                            <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Как вас зовут</label>
                                                            <div class="mt-2">
                                                                <input type="text" name="name" id="name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none px-2.5" v-model="registerFormData.name"/>
                                                                <p v-if="'name' in registerFormData.errors" class="text-red-500 text-xs italic" v-html="registerFormData.errors.name.join('<br />')" />
                                                            </div>
                                                        </div>

                                                        <div class="col-span-full">
                                                            <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Номер телефона</label>
                                                            <div class="mt-2">
                                                                <input type="text" placeholder="+7 (###) ###-##-##" v-maska data-maska="+7 (###) ###-##-##" name="phone" id="phone" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none px-2.5" v-model="registerFormData.phone"/>
                                                                <p v-if="'phone' in registerFormData.errors" class="text-red-500 text-xs italic" v-html="registerFormData.errors.phone.join('<br />')" />
                                                            </div>
                                                        </div>

                                                        <div class="col-span-full">
                                                            <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Гос номер авто (Русские буквы)</label>
                                                            <div class="mt-2">
                                                                <input type="text" placeholder="A 000 AA 00X" v-maska:[optionsMaska] name="number" id="number" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none px-2.5" v-model="registerFormData.number"/>
                                                                <p v-if="'number' in registerFormData.errors" class="text-red-500 text-xs italic" v-html="registerFormData.errors.number.join('<br />')" />
                                                            </div>
                                                        </div>

                                                        <div class="col-span-full">
                                                            <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Марка авто</label>
                                                            <div class="mt-2">
                                                                <input type="text" name="brand" id="brand" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none px-2.5" v-model="registerFormData.brand"/>
                                                                <p v-if="'brand' in registerFormData.errors" class="text-red-500 text-xs italic" v-html="registerFormData.errors.brand.join('<br />')" />
                                                            </div>
                                                        </div>

                                                        <div class="col-span-full">
                                                            <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Модель авто</label>
                                                            <div class="mt-2">
                                                                <input type="text" name="model" id="model" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none px-2.5" v-model="registerFormData.model"/>
                                                                <p v-if="'model' in registerFormData.errors" class="text-red-500 text-xs italic" v-html="registerFormData.errors.model.join('<br />')" />
                                                            </div>
                                                        </div>

                                                        <div class="col-span-full">
                                                            <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Год выпуска</label>
                                                            <div class="mt-2">
                                                                <input type="text" name="year" id="year" placeholder="####" v-maska data-maska="####" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none px-2.5" v-model="registerFormData.year"/>
                                                                <p v-if="'year' in registerFormData.errors" class="text-red-500 text-xs italic" v-html="registerFormData.errors.year.join('<br />')" />
                                                            </div>
                                                        </div>

                                                        <div class="col-span-full">
                                                            <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Ваш город</label>
                                                            <div class="mt-2">
                                                                <input type="text" name="city" id="city" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none px-2.5" v-model="registerFormData.city"/>
                                                                <p v-if="'city' in registerFormData.errors" class="text-red-500 text-xs italic" v-html="registerFormData.errors.city.join('<br />')" />
                                                            </div>
                                                        </div>

                                                        <div class="col-span-full">
                                                            <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Пароль</label>
                                                            <div class="mt-2">
                                                                <input type="password" name="password" id="password" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none px-2.5" v-model="registerFormData.password"/>
                                                                <p v-if="'password' in registerFormData.errors" class="text-red-500 text-xs italic" v-html="registerFormData.errors.password.join('<br />')" />
                                                            </div>
                                                        </div>

                                                        <div class="col-span-full">
                                                            <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Повторите пароль</label>
                                                            <div class="mt-2">
                                                                <input type="password" name="confirm-password" id="confirm-password" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none px-2.5" v-model="registerFormData.password_confirmation"/>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="bg-gray-50 px-4 py-3 flex justify-center sm:ml-4">
                                    <button type="button" class="inline-flex w-full justify-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500/75" @click="registerFormData.send">Зарегистрироваться</button>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>

        <TransitionRoot as="template" :show="authFormData.visible">
            <Dialog class="relative z-150" @close="authFormData.close">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div class="fixed inset-0 w-screen overflow-y-auto">
                    <div class="flex min-h-full items-center justify-center p-4 text-center">
                        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all max-md:grow md:basis-3/6 lg:basis-96">
                                <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div class="flex items-start">
                                        <div class="mt-3 sm:ml-4 sm:mt-0 sm:text-left flex-grow">
                                            <div class="flex items-center justify-between">
                                                <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900 uppercase">Авторизация</DialogTitle>
                                                <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="authFormData.close">
                                                    <span class="sr-only">Close menu</span>
                                                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                            <form>
                                                <div class="border-b border-gray-900/10 pb-12">
                                                    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 grid-cols-6">
                                                        <div class="col-span-full">
                                                            <p v-if="'global' in authFormData.errors" class="text-red-500 text-xs italic" v-html="authFormData.errors.global.join('<br />')" />
                                                        </div>
                                                        <div class="col-span-full">
                                                            <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Номер телефона</label>
                                                            <div class="mt-2">
                                                                <input type="text" placeholder="+7 (###) ###-##-##" v-maska data-maska="+7 (###) ###-##-##" name="phone" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none px-2.5"  v-model="authFormData.phone"/>
                                                            </div>
                                                        </div>

                                                        <div class="col-span-full">
                                                            <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Пароль</label>
                                                            <div class="mt-2">
                                                                <input type="password" name="password" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none px-2.5" v-model="authFormData.password"/>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="bg-gray-50 px-4 py-3 flex justify-center sm:ml-4 ">
                                    <button type="button" class="inline-flex w-full justify-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500/75" @click="authFormData.send">Войти</button>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>

        <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
            <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
        </div>
        <div class="absolute inset-x-0 top-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:top-0" aria-hidden="true">
            <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
        </div>

    </div>
</template>

<script setup>

import tx from "@/system";
import {provide, reactive, ref} from "vue";
import {vMaska} from "maska"
import {useRoute, useRouter} from "vue-router";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon, BellIcon } from '@heroicons/vue/24/outline'

const mobileMenuOpen = ref(false)

const route  = useRoute()
const router = useRouter()

const optionsMaska = reactive({
    mask: "U ### UU ##0",
    tokens : {
        0 : { pattern : /[0-9]/,optional : true},
        U : {pattern : /[АВСЕНКМОРТХУ]/, transform: (chr) => chr.toUpperCase()}
    }
})

const registerFormData = reactive({
    visible : false,
    name : '',
    phone : '',
    number : '',
    brand : '',
    model : '',
    year : '',
    city : '',
    password : '',
    confirm_password : '',
    errors : {},
    send : function(){
        tx.data.full_loader = true
        tx.service.app.installToken();
        fetch(tx.data.server + '/register', {
            credentials: 'include',
            method     : 'post',
            headers    : {
                'Content-Type'    : 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-XSRF-TOKEN'    : tx.data.user.token,
                'Accept'          : 'application/json'
            },
            body       : JSON.stringify(this)
        }).then(response => {
            return new Promise(async (resolve,reject) => {
                if (response.status === 201) {
                    resolve();
                } else if (response.status === 422){
                    reject(await response.json())
                } else if (response.status === 429){
                    reject({errors : {global : ['Слишком много попыток. Попробуйте повторить позже']}})
                } else
                    reject({errors : {global : ['Проблема маршрута регистрации']}})
            })
        }).then(() => {
            tx.service.app.authentication(true);
            registerFormData.close();
        }).catch(data => {
            registerFormData.errors = data.errors
        }).finally(() => {
            tx.data.full_loader = false
        })
    },
    open : () => {
        mobileMenuOpen.value = false;
        registerFormData.visible = true;
    },
    close : () => {
        registerFormData.visible = false;
    }
})

const authFormData = reactive({
    visible : false,
    phone   : '',
    password: '',
    errors : {},
    send : function(){
        tx.data.full_loader = true
        tx.service.app.installToken();
        fetch(tx.data.server + '/login', {
            credentials: 'include',
            method     : 'post',
            headers    : {
                'Content-Type'    : 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-XSRF-TOKEN'    : tx.data.user.token,
                'Accept'          : 'application/json'
            },
            body       : JSON.stringify(this)
        }).then(response => {
            return new Promise(async (resolve,reject) => {
                if (response.status === 200) {
                    resolve();
                } else if (response.status === 422){
                    reject({errors : {global : ['Некорректные учетные данные']}})
                } else if (response.status === 429){
                    reject({errors : {global : ['Слишком много попыток. Попробуйте повторить позже']}})
                } else
                    reject({errors : {global : ['Проблема маршрута авторизации']}})
            })
        }).then(() => {
            tx.service.app.authentication(true);
            authFormData.close();
        }).catch(data => {
            authFormData.errors = data.errors
        }).finally(() =>{
            tx.data.full_loader = false
        });
    },
    open : () => {
        mobileMenuOpen.value = false;
        authFormData.visible = true;
    },
    close : () => {
        authFormData.visible = false;
    }
})

router.beforeEach((to, from, next) => {
    tx.service.app.authentication().then(() => next()).catch(() => {
        if(to.meta["auth"]){
            tx.service.user.clear()
            next('/');
        }else next();
    }).finally(() =>{
        tx.data.full_loader = false
    })
})

provide('tx', tx);

const userNavigation = [
    {
        name: 'Профиль',
        href: '#',
        event : function(){

        }
    },
    {
        name: 'Путевой лист',
        href: '#',
        event : function(){
            window.open(tx.data.server + '/waybill');
        }
    },{
        name: 'Настройки',
        href: '#',
        event : function(){

        }
    },
    {
        name: 'Выход',
        href: '#',
        event : function(){
            tx.data.full_loader = true
            fetch(tx.data.server + '/logout',{
                credentials : 'include',
                method : 'post',
                headers : {
                    'Content-Type'      : 'application/json',
                    'X-Requested-With'  : 'XMLHttpRequest',
                    'X-XSRF-TOKEN'      : tx.data.user.token,
                    'Accept'            : 'application/json'
                }
            }).then(response => {
                if(response.status === 204)
                    tx.service.user.clear()
            }).finally(() => {
                tx.data.full_loader = false
            })
        }
    },
]

</script>
