import {ElNotification, ElLoading} from "element-plus";
import {reactive} from "vue";

const tx = {
    // app : reactive({
    //     server : process.env.NODE_ENV === 'development' ? 'http://localhost:8888' : 'http://new.taxi-portal.ru'
    // }),
    service : {
        app : {
            installToken : () => {
                let name = 'XSRF-TOKEN'
                let matches = document.cookie.match(new RegExp(
                    "(?:^|; )" + name.replace(/([.$?*|{}()\[\]\\\/+^])/g, '\\$1') + "=([^;]*)"
                ));
                tx.data.user.token = matches ? decodeURIComponent(matches[1]) : null;
            },
            authentication : (reset = false) => new Promise((resolve, reject) => {
                    //Получение токена если не установлен
                    if(reset || null === tx.data.user.token){
                        fetch(tx.data.server + '/sanctum/csrf-cookie',{
                            credentials: 'include',
                            method : 'get'
                        }).then(response => {
                            if(response.status === 204) {
                                tx.service.app.installToken();
                                resolve()
                            }else{
                                tx.service.error();
                                reject(new Error('Проблема получения токена'));
                            }
                        })
                    }else resolve();
                }).then(() => new Promise((resolve, reject) => {
                    //Проверка авторизации
                        fetch(tx.data.server + '/api/check',{
                            credentials : 'include',
                            method : 'post',
                            headers : {
                                'Content-Type'      : 'application/json',
                                'X-Requested-With'  : 'XMLHttpRequest',
                                'X-XSRF-TOKEN'      : tx.data.user.token
                            }
                        }).then(response => {
                            response.status === 204 ? resolve() : reject(new Error('Пользователь не авторизован'))
                        })
                    })
                ).then(() => new Promise((resolve, reject) => {
                    //Получение профиля пользователя если еще не заполнялся
                        if(false === tx.data.user.filled || reset) {
                            fetch(tx.data.server + '/api/profile', {
                                credentials: 'include',
                                method     : 'post',
                                headers    : {
                                    'Content-Type'    : 'application/json',
                                    'X-Requested-With': 'XMLHttpRequest',
                                    'X-XSRF-TOKEN'    : tx.data.user.token
                                }
                            }).then(data => {
                                if (data.status === 200) {
                                    data.json().then(res => {
                                        tx.service.user.fill(res)
                                        resolve()
                                    }).catch(() => reject(new Error('Не удалось получить профиль пользователя')))
                                } else reject(new Error('Не удалось получить профиль пользователя'));
                            })
                        }else resolve();
                    })
                ).catch(err => {
                    console.log(err);
                }),
        },
        user : {
            fill : (data) => {
                tx.data.user.id        = data.id
                tx.data.user.name      = data.name
                tx.data.user.phone     = data.phone
                tx.data.user.authorize = true
                tx.data.user.filled    = true
            },
            clear : () => {
                tx.data.user.id        = ''
                tx.data.user.name      = ''
                tx.data.user.phone     = ''
                tx.data.user.token     = null
                tx.data.user.authorize = false
                tx.data.user.filled    = false
            },
        },
        query : {
          v1 : {
              defaultResponseProcess : async (response) => new Promise(resolve => {
                  tx.service.loading = false;
                  if(response.status === 204){
                      resolve(response)
                  }else if(response.status === 403){
                      response.json().then(data => {
                          tx.service.notify({
                              type: 'error',
                              message : data.message,
                          })
                      })
                  }else if(response.status === 422){
                      response.json().then(data => {
                          tx.service.notify({
                              type: 'error',
                              message : data.message,
                          })
                      })
                  }else{
                      tx.service.error();
                  }
              }),
              defaultResponseProcessGet : async (response) => new Promise(resolve => {
                  tx.service.loading = false;
                  if(response.status === 200){
                      response.json().then(data => {
                          resolve(data)
                      }).catch(() => {
                          tx.service.notify({
                              type: 'error',
                              message : "Некорректный ответ сервера. Обратитесь к администратору",
                          })
                      });
                  }else if(response.status === 422){
                      response.json().then(data => {
                          tx.service.notify({
                              type: 'error',
                              message : data.message,
                          })
                      })
                  }else{
                      tx.service.error();
                  }
              }),
              get : async (url, customResponseProcess = false) => {
                  let response = await fetch(tx.data.server + '/api/v1' + url,{
                      credentials : 'include',
                      method : 'get',
                      headers : {
                          'Content-Type'      : 'application/json',
                          'X-Requested-With'  : 'XMLHttpRequest',
                          'X-XSRF-TOKEN'      : tx.data.user.token
                      }
                  })
                  return customResponseProcess ? await response : await tx.service.query.v1.defaultResponseProcessGet(response);
              },
              post : async (url, body = {}, customResponseProcess = false) => {
                  let response =  await fetch(tx.data.server + '/api/v1' + url,{
                      credentials : 'include',
                      method : 'post',
                      headers : {
                          'Content-Type'      : 'application/json',
                          'X-Requested-With'  : 'XMLHttpRequest',
                          'X-XSRF-TOKEN'      : tx.data.user.token
                      },
                      body : JSON.stringify(body)
                  })
                  return customResponseProcess ? await response : await tx.service.query.v1.defaultResponseProcess(response);
              },
              put : async function(url, body = {}, customResponseProcess = false){
                  let response =  await fetch(tx.data.server + '/api/v1' + url,{
                      credentials : 'include',
                      method : 'put',
                      headers : {
                          'Content-Type'      : 'application/json',
                          'X-Requested-With'  : 'XMLHttpRequest',
                          'X-XSRF-TOKEN'      : tx.data.user.token
                      },
                      body : JSON.stringify(body)
                  })
                  return customResponseProcess ? await response : await tx.service.query.v1.defaultResponseProcess(response);
              },
              delete : async function(url, customResponseProcess = false){
                  let response =  await fetch(tx.data.server + '/api/v1' + url,{
                      credentials : 'include',
                      method : 'delete',
                      headers : {
                          'Content-Type'      : 'application/json',
                          'X-Requested-With'  : 'XMLHttpRequest',
                          'X-XSRF-TOKEN'      : tx.data.user.token
                      }
                  })
                  return customResponseProcess ? await response : await tx.service.query.v1.defaultResponseProcess(response);
              }
          }
        },
        notify : ({message,type}) => {
            ElNotification({
                message : message,
                type : type,
                dangerouslyUseHTMLString: true,
                position : 'bottom-right'
            })
        },
        successAdd : () => {
            tx.service.notify({
                type: 'success',
                message : 'Запись успешно добавлена',
                position : 'bottom-right'
            })
        },
        successEdit : () => {
            tx.service.notify({
                type: 'success',
                message : 'Запись успешно обновлена',
                position : 'bottom-right'
            })
        },
        successRemove : () => {
            tx.service.notify({
                type: 'success',
                message : 'Запись успешно удалена',
                position : 'bottom-right'
            })
        },
        successRestore : () => {
            tx.service.notify({
                type: 'success',
                message : 'Запись успешно востановлена',
                position : 'bottom-right'
            })
        },
        error : () => {
            tx.service.notify({
                type   : 'error',
                message: 'Непредвиденная ошибка обратитесь к администратору'
            })
        },
    },
    data : reactive({
        server: process.env.NODE_ENV === 'development' ? 'http://localhost:9898' : 'https://service.taxi-portal.ru',
        full_loader : true,
        user : {
            id       : '',
            name     : '',
            email    : '',
            phone    : '',
            position : '',
            authorize: false,
            filled   : false,
            token    : null,
            accesses : []
        },
        company : {
            name : 'Такси портал',
        },
        access: {
            MAIN                    : 'main',
            ACCESS_COMPANY          : 'access_company',
            CREATE_COMPANY          : 'create_company',
            UPDATE_COMPANY          : 'update_company',
            CREATE_DEPARTMENT       : 'create_department',
            ACCESS_DEPARTMENT       : 'access_department',
            UPDATE_DEPARTMENT       : 'update_department',
            SHOW_USER               : 'show_user',
            CREATE_USER             : 'create_user',
            UPDATE_USER             : 'update_user',
            ACCESS_USER             : 'access_user',
            CREATE_RATE_COMPANY     : 'create_rate_company',
            UPDATE_RATE_COMPANY     : 'update_rate_company',
            SHOW_RATE_COMPANY       : 'show_rate_company',
            CREATE_RATE_DEPARTMENT  : 'create_rate_department',
            UPDATE_RATE_DEPARTMENT  : 'update_rate_department',
            SHOW_RATE_DEPARTMENT    : 'show_rate_department',
            CREATE_AUTO             : 'create_auto',
            UPDATE_AUTO             : 'update_auto',
            ACCESS_LIST_AUTO        : 'access_list_auto',
            ACCESS_LIST_DRIVER      : 'access_list_driver',
            CREATE_DRIVER           : 'create_driver',
            UPDATE_DRIVER           : 'update_driver',
            ACCESS_LIST_LICENSE     : 'access_list_license',
            CREATE_LICENSE          : 'create_license',
            UPDATE_LICENSE          : 'update_license',
            ACCESS_LIST_TO          : 'access_list_to',
            CREATE_TO               : 'create_to',
            UPDATE_TO               : 'update_to',
            CREATE_RATE_AUTO        : 'create_rate_auto',
            UPDATE_RATE_AUTO        : 'update_rate_auto',
            SHOW_RATE_AUTO          : 'show_rate_auto',
            CREATE_PAY              : 'create_pay',
            UPDATE_PAY              : 'update_pay',
            ALL_LIST_PAY            : 'all_list_pay',
            TWO_MONTH_LIST_PAY      : 'two_month_list_pay',
            DELETE_PAY              : 'delete_pay',
            CONFIRM_DELETE_PAY      : 'confirm_delete_pay',
            BALANCE_DEPARTMENT      : 'balance_department',
            ACCESS_REPORT_DEPARTMENT: 'access_report_department',
            REPORT_WRITE_OFF        : 'report_write_off',
        },
        accessCategory : {
            main : () => [
                {
                    code : tx.data.access.MAIN
                }
            ],
            show_company : () => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.ACCESS_COMPANY
                }
            ],
            create_company : () => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.CREATE_COMPANY
                }
            ],
            update_company : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.UPDATE_COMPANY,
                    many_id : manyId
                }
            ],
            create_department : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.CREATE_DEPARTMENT,
                    many_id : manyId
                }
            ],
            update_department : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.UPDATE_DEPARTMENT,
                    many_id : manyId
                }
            ],
            show_department : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.ACCESS_DEPARTMENT,
                    many_id : manyId
                }
            ],
            create_user : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.CREATE_USER,
                    many_id : manyId
                }
            ],
            update_user : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.UPDATE_USER,
                    many_id : manyId
                }
            ],
            show_user : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.SHOW_USER,
                    many_id : manyId
                }
            ],
            access_user : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.ACCESS_USER,
                    many_id : manyId
                }
            ],
            create_rate_company : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.CREATE_RATE_COMPANY,
                    many_id : manyId
                }
            ],
            show_rate_company : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.SHOW_RATE_COMPANY,
                    many_id : manyId
                }
            ],
            update_rate_company : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.UPDATE_RATE_COMPANY,
                    many_id : manyId
                }
            ],
            create_rate_department : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.CREATE_RATE_DEPARTMENT,
                    many_id : manyId
                }
            ],
            show_rate_department : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.SHOW_RATE_DEPARTMENT,
                    many_id : manyId
                }
            ],
            update_rate_department : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.UPDATE_RATE_DEPARTMENT,
                    many_id : manyId
                }
            ],
            create_auto : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.CREATE_AUTO,
                    many_id : manyId
                }
            ],
            update_auto : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.UPDATE_AUTO,
                    many_id : manyId
                }
            ],
            create_driver : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.CREATE_DRIVER,
                    many_id : manyId
                }
            ],
            list_driver : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.ACCESS_LIST_DRIVER,
                    many_id : manyId
                }
            ],
            update_driver : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.UPDATE_DRIVER,
                    many_id : manyId
                }
            ],
            add_driver_auto : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.ACCESS_LIST_DRIVER,
                    many_id : manyId
                }
            ],
            list_license : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.ACCESS_LIST_LICENSE,
                    many_id : manyId
                }
            ],
            create_license : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.CREATE_LICENSE,
                    many_id : manyId
                }
            ],
            update_license : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.UPDATE_LICENSE,
                    many_id : manyId
                }
            ],
            list_to : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.ACCESS_LIST_TO,
                    many_id : manyId
                }
            ],
            create_to : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.CREATE_TO,
                    many_id : manyId
                }
            ],
            update_to : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.UPDATE_TO,
                    many_id : manyId
                }
            ],
            create_rate_auto : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.CREATE_RATE_AUTO,
                    many_id : manyId
                }
            ],
            show_rate_auto : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.SHOW_RATE_AUTO,
                    many_id : manyId
                }
            ],
            update_rate_auto : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.UPDATE_RATE_AUTO,
                    many_id : manyId
                }
            ],
            create_pay : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.CREATE_PAY,
                    many_id : manyId
                }
            ],
            update_pay : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.UPDATE_PAY,
                    many_id : manyId
                }
            ],
            show_all_pay : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.ALL_LIST_PAY,
                    many_id : manyId
                }
            ],
            delete_pay : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.DELETE_PAY,
                    many_id : manyId
                }
            ],
            show_auto : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.ACCESS_LIST_AUTO,
                    many_id : manyId
                }
            ],
            confirm_delete_pay : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.CONFIRM_DELETE_PAY,
                    many_id : manyId
                }
            ],
            balance_department : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.BALANCE_DEPARTMENT,
                    many_id : manyId
                }
            ],
            access_report_department : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.ACCESS_REPORT_DEPARTMENT,
                    many_id : manyId
                }
            ],
            report_write_off : (manyId) => [
                {
                    code : tx.data.access.MAIN
                },
                {
                    code : tx.data.access.REPORT_WRITE_OFF,
                    many_id : manyId
                }
            ],
        }
    }),
};

export default tx;
